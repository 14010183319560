.Count_cart_items{
    position: absolute;
    top: -8px;
    right: -15px;
    background-color: #ff0000;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    transform: scale(0.8);
    transform-origin: top right;
    transition: all 0.3s ease;
    z-index: -1;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 20px;
}



.cart_items{
    position: relative;
}